import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Power4 } from "gsap";
gsap.registerPlugin(ScrollTrigger);
var haveYper = /** @class */ (function () {
    function haveYper() {
        this.animationHaveYper();
    }
    haveYper.prototype.animationHaveYper = function () {
        gsap.to(".wrapper--contact", {
            y: 0,
            autoAlpha: 1,
            display: 'flex',
            ease: Power4.easeIn,
            scrollTrigger: {
                trigger: ".wrapper--header",
                start: 'top+=10% top',
                end: "top+=12% bottom-=20%",
                toggleActions: "play none reverse none",
            }
        }),
            gsap.to(".wrapper--contact", {
                display: 'flex',
                position: "relative",
                width: "100%",
                borderRadius: "0",
                height: "120px",
                ease: Power4.easeIn,
                scrollTrigger: {
                    toggleActions: "play none reverse none",
                    trigger: "footer",
                    start: 'top-=1% bottom',
                    end: "center center-=20%",
                }
            });
    };
    return haveYper;
}());
$(function () {
    new haveYper();
});
